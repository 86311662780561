export const PLEDGE_COLUMNS = [
  {
    title: 'Total Open',
    dataIndex: 'totalOpenPledgeAmount',
    key: 'totalOpen',
  },
  {
    title: 'All Time Lent',
    dataIndex: 'allTimeLentPledgeAmount',
    key: 'allTimeLent',
  },
  {
    title: 'All Time Picked-up',
    dataIndex: 'allTimePickedUpPledgeAmount',
    key: 'allTimePickedUp',
  },
  {
    title: 'Redemption Rate',
    dataIndex: 'redemptionRate',
    key: 'redemptionRate',
  },
];

export const PURCHASE_COLUMNS = [
  {
    title: 'Total Open',
    dataIndex: 'totalOpenPurchaseAmount',
    key: 'totalOpen',
  },
  {
    title: 'All Time Sold',
    dataIndex: 'allTimeSoldPurchaseAmount',
    key: 'allTimeSold',
  },
  {
    title: 'All Time Collected',
    dataIndex: 'allTimePickedUpPurchaseAmount',
    key: 'allTimeCollected',
  },
  {
    title: 'Collection Rate',
    dataIndex: 'redemptionRate',
    key: 'collectionRate',
  },
];

export const RETAIL_COLUMNS = [
  {
    title: 'Total Sales',
    dataIndex: 'totalSales',
    key: 'totalSales',
  },
  {
    title: 'Total Refunds',
    dataIndex: 'totalRefunds',
    key: 'totalRefunds',
  },
  {
    title: 'Total Exchange',
    dataIndex: 'totalExchange',
    key: 'totalExchange',
  },
];

export const LAYAWAY_ACCOUNT_COLUMNS = [
  {
    title: 'Item/Value',
    dataIndex: 'itemValue',
  },
  {
    title: 'Account Balance',
    dataIndex: 'accountBalance',
  },
  {
    title: 'Unallocated Funds',
    dataIndex: 'unallocatedFunds',
  },
  {
    title: 'Shortfall To Date',
    dataIndex: 'shortfallToDate',
  },
  {
    title: 'Shortfall To Collect',
    dataIndex: 'shortfallToCollect',
  },
];

export const TRAVEL_MONEY_COLUMNS = [
  {
    title: 'All Time Bought',
    dataIndex: 'totalBuy',
    key: 'allTimeBought',
  },
  {
    title: 'All Time Sold',
    dataIndex: 'totalSell',
    key: 'allTimeSold',
  },
  {
    title: 'Rate Guarantee',
    dataIndex: 'rateGuranteeCount',
    key: 'rateGuarantee',
  },
];

export const WESTERN_UNION_COLUMNS = [
  {
    title: 'All Time Sent',
    dataIndex: 'allTimeSent',
  },
  {
    title: 'All Time Received',
    dataIndex: 'allTimeReceived',
  },
  {
    title: 'Most Common Destination',
    dataIndex: 'mostCommonDestination',
  },
];

export const CHEQUE_CASHING_COLUMNS = [
  {
    title: 'All Time Cash',
    dataIndex: 'allTimeCashed',
    key: 'allTimeCashed',
  },
  {
    title: 'All Time Returned',
    dataIndex: 'allTimeReturned',
    key: 'allTimeReturned',
  },
  {
    title: 'All Time Cleared',
    dataIndex: 'allTimeCleared',
    key: 'allTimeCleared',
  },
];

export const PERSONAL_LOAN_COLUMNS = [
  {
    title: 'Total Open',
    dataIndex: 'totalOpen',
  },
  {
    title: 'All Time Lent',
    dataIndex: 'allTimeLent',
  },
];

export const VISIT_COLUMN = [
  {
    title: 'Store',
    dataIndex: 'store',
  },
  {
    title: 'Date & Time',
    dataIndex: 'dateTime',
  },
];

export const MARKETING_PREFERENCES = [
  {
    key: 'marketingPostAccepted',
    text: 'Mail',
    id: 1,
  },
  {
    key: 'marketingSmsAccepted',
    text: 'Text',
    id: 2,
  },
  {
    key: 'marketingEmailAccepted',
    text: 'Email',
    id: 3,
  },
  {
    key: 'marketingPhoneAccepted',
    text: 'Phone',
    id: 4,
  },
  {
    key: 'marketingOptOut',
    text: 'Opt-out of all Marketing',
    id: 5,
  },
];

export const TITLES = ['Mr', 'Mrs', 'Miss', 'Ms', 'Mx', 'Dr', 'Other'];

export const ID_TYPES = ['Type A', 'Type B'];

export const CUSTOMER_KYC_STATUS = [
  'purple',
  'green',
  'red',
  'yellow',
  'amber',
  'black',
];

export const CUSTOMER_PROFILE_LABEL = {
  RED: 'Account Blocked',
  YELLOW: 'Lite Registration',
  PURPLE: 'Store Assisted',
  GREEN: 'Full Registration',
  ERROR: 'No Details for customer found',
  AMBER: 'At Risk Account',
  BLACK: 'Monitored Account',
};
export const PROFILE_STATUS = {
  GREEN: 'green',
  YELLOW: 'yellow',
  RED: 'red',
  PURPLE: 'purple',
  BLACK: 'black',
  AMBER: 'amber',
};

export const MARKETING_PREFERENCE_TEXT = {
  LINE_ONE: `Please ask the customer if they would like to opt into our marketing communications by each channel, by reading:`,
  LINE_TWO: `We'd love to keep in touch with exclusive offers and the latest news from H&T. You can ask us to stop at any time and we wont share your data with anyone else`,
};

export const CUSTOMER_REFERRAL_TEXT = {
  LINE_ONE: `Has this customer been referred?`,
  LINE_TWO: `If yes, please select the checkbox`,
};

export const PERSONAL_INFORMATION_HEADER = 'Personal Information';
export const ADDRESS_DETAILS_HEADER = 'Address Details';
export const CONTANT_DETAILS_HEADER = 'Contact Details';

export const ERROR_MESSAGE_ON_UPDATE = {
  message: 'Error Updating Customer',
  description:
    'Error occurred while updating customer. Please check all fields and try again.',
};

export const SUCCESS_MESSAGE_ON_UPDATE = {
  message: 'Customer Updated',
  description: 'Customer was successfully updated.',
};

export const DOB_ERROR_MESSAGE = {
  MIN_AGE_PRE_TEXT: 'Customers must be ', //keeping extra space after intentionally
  MIN_AGE_POST_TEXT: ' or over to register.', //keeping extra space before intentionally
  GREATER_THAN_150:
    'It is not possible to register a date of birth older than 150 years.',
};

export const DOB_ALLOWEd_AGE = {
  MIN_AGE: 18,
  MIN_AGE_TM_LITE: 16,
  MAX_AGE: 150,
};

export const INVALID_DATE_MESSAGE = 'Date is not valid.';

export const EXPIRY_DATE_ERROR_MESSAGE = {
  EXPIRED: 'Expiry date cannot be in the past.',
};

export const MARKETING_PREFERENCE_HEADER = 'Marketing Preference';
export const LETTER_PREFERENCES = {
  ELECTRONIC: 'Electronic',
  PAPER: 'Paper',
};

export const CUSTOMER_HEADER_LABEL = {
  EDIT_PROFILE: 'Edit Customer',
  ADD_NEW_CUSTOMER: 'Create New Customer',
  GDPR_ACCEPTED: 'GDPR terms accepted',
};

export const SUCCESS_MESSAGE_ON_UPLOAD_IMAGE = {
  message: 'Customer Image Uploaded',
  description: 'Customer image uploaded successfully',
};

export const SUCCESS_MESSAGE_ON_UPLOAD_DOC = {
  message: 'Customer document Uploaded',
  description: 'Customer document uploaded successfully',
};

export const SUCCESS_MESSAGE_ON_NOMINEE_UPLOAD_IMAGE = {
  message: 'Nominee Image Uploaded',
  description: 'Nominee image uploaded successfully',
};

export const ERROR_MESSAGE_ON_UPLOAD_IMAGE = {
  message: 'Error Customer Image Uploaded',
  description:
    'Error occurred while updating customer. Please check all fields and try again',
};

export const ERROR_MESSAGE_ON_NOIMINEE_UPLOAD_IMAGE = {
  message: 'Error Nominee Image Uploaded',
  description: 'Error occurred while updating nominee image. Please try again',
};

export const ERROR_MESSAGE_ON_UPLOAD_DOC = {
  message: 'Error Customer Document Uploaded',
  description:
    'Error occurred while updating customer. Please check all fields and try again',
};

export const CAPTURE_ID = 'Capture ID';
export const CAPTURING = 'Capturing...';
export const SCANNING = 'Scanning...';

export const DOCUMENT_TYPE_OTHER = 34;
export const DOCUMENT_TYPE_USE_CAMERA = 99;

export const CHANGE_PHOTO = 'Change Photo';

export const IMAGE_UPLOADING_TEXT = 'Image uploading please wait...';

export const CUSTOMER_ERROR = 'Customer Error';

export const NOTES_TITLE = 'Notes';

export const WESTERN_UNION_DIRECTION = {
  RECEIVED: 'Received',
};

export const SUCCESS_MESSAGE_ON_ADD_CUSTOMER = {
  message: 'Customer Created',
  description: 'Customer was successfully created.',
};

export const VERIFY_CUSTOMER_IDENTITY = `Select and confirm at least one identifier to verify your customer and continue`;

export const VERIFY_CUSTOMER_IDENTITIES = `Select and confirm at least two identifiers to verify your customer and continue`;

export const isFrontCustomer = (fullName, optionalText = '') =>
  `Is the customer ${fullName} in front of you? ${optionalText}`;

export const isPhoneCustomer = (fullName) =>
  `Is the customer ${fullName} on the phone to you?`;

export const GDPR_INFO = {
  GDPR_MESSAGE: {
    LINE_ONE: 'Read the following statement to the customer',
    LINE_TWO:
      'In order for us to process any financial services we need to collect and store your personal data, our privacy notice sets out how we use and store your data. Would you like to review the privacy notice before we continue?',
    LINE_THREE: 'Select proceed when your customer is happy to continue.',
  },
  GDPR_DECLINE_MESSAGE:
    'Unfortunately we are unable to continue with this enquiry as we need to take personal details to continue.',
  PRINT_PRIVACY_NOTICE: 'Print Privacy Notice',
  PROCEED: 'Proceed',
  DECLINE: 'Decline',
};
export const SORRY_MESSAGE = `Sorry we couldn't find any matches`;

export const ENTER_ADDRESS_MANUALLY = 'Click here to enter address manually';
export const RETAIL_SUMMARY_ENUMS = {
  SOLID: 'Sold',
  REFUNDED: 'refunded',
};

export const REVIEW = 'Review';
export const ADD_NOTE = 'Add Note';
export const ADD_A_NEW_NOTE = 'Add a New Note';
export const ADD_TO_STOP_LIST_HEADING = `Add To Stop List (Internal Notes Only)`;
export const REMOVE_FROM_STOP_LIST_HEADING = `Remove Customer From Stop List (Internal Notes Only)`;
export const REMOVE_FROM_STOP_LIST_DESCRIPTION = `The customer will be removed from stop list and their account will no longer be blocked`;
export const ADD_TO_STOP_LIST_DESCRIPTION = `The customer will be marked as blocked and added to stop List.`;

export const REMOVE_FROM_SANCTION_STOP_LIST_HEADING = `Remove Customer From Sanction Stop List (Internal Notes Only)`;
export const REMOVE_FROM_SANCTION_STOP_LIST_DESCRIPTION = `The customer will be removed from Sanction stop list and their account will no longer be blocked`;

export const VIEW_NOTES = 'View Notes';
export const SAVE = 'Save';
export const CANCEL = 'Cancel';
export const REMOVE_PROFANITY = 'Ok';
export const MORE_INFO = 'More Information...';
export const ADD_TO_STOP_LIST = 'Add To Stop List';
export const REMOVE_FROM_STOP_LIST = 'Remove From Stop List';

export const CUSTOMER_NOTE_TYPE_ID = {
  CUSTOMER_ADDED_TO_STOP_LIST: 13,
  CUSTOMER_REMOVED_FROM_STOP_LIST: 14,
  CUSTOMER_REMOVED_FROM_STOP_LIST_SANCTION: 22,
  CUSTOMER_ADD_TO_STOP_LIST_SANCTION: 21,
};

export const USER_ROLE = {
  FINANCIAL_CRIME_TEAM: 'Financial Crime Team',
};

export const NOTIFICATION_MESSAGES_NOTES = {
  ADD: {
    SUCCESS: {
      MESSAGE: 'Customer Note Created',
      DESCRIPTION: 'Customer Note successfully created.',
    },
    ERROR: {
      MESSAGE: 'Error In Customer Note Creation',
      DESCRIPTION: 'Customer Note is not created. Try Later',
    },
  },
  LOAD: {
    SUCCESS: {
      MESSAGE: 'Customer Notes Fetching',
      DESCRIPTION: 'Customer Notes are successfully fetched.',
    },
    ERROR: {
      MESSAGE: 'Error In Customer Notes Fetching',
      DESCRIPTION: 'Customer Notes are not fetched. Try Later',
    },
  },
};

export const NOTIFICATION_MESSAGES_NOTE_TYPES = {
  LOAD: {
    SUCCESS: {
      MESSAGE: 'Customer Note Type Fetching',
      DESCRIPTION: 'Customer Note Types are successfully fetched.',
    },
    ERROR: {
      MESSAGE: 'Error In Customer Note Type Fetching',
      DESCRIPTION: 'Customer Note Type are not fetched. Try Later',
    },
  },
};

export const DOCUMENT_CAPTURE_TITLE = 'Document ID Capture';

export const PURCHASE_STATUS = {
  HOLD: 'Hold',
  PFI: 'PFI',
  RETURNED: 'Returned',
};

export const PURCHASE_STATUS_ID = {
  HOLD: 0,
  RETURNED: 2, //picked up
  PFI: 3,
};

export const RECALL_MSG = {
  START_BASKET_JOURNEY:
    'The customer already has a live basket. You will be directed to review this basket before commencing with any other services.',
  WITHIN_JOURNEY:
    ' The customer already has a live basket within the recall. These transactions will merge with your current basket where you can review.',
  START_CHECKOUT_JOURNEY:
    'This customer already has a live basket in the checkout. You will be directed to the checkout to review before commencing with other services.',
  REMOVE_PROFANITY: 'Please remove profanity before saving notes.',
  PROFANITY_WORDS: 'Suspected profanity words:',
};

export const PROFANITY_CHECK = {
  REMOVE_PROFANITY: 'Please remove profanity before saving notes.',
  PROFANITY_WORDS: 'Suspected profanity words:',
};

export const RECALL_TITLE = {
  BASKET: 'Recall Basket',
  CHECKOUT: 'Recall Checkout',
  NOTES: 'Alert',
};

export const ALERT_MESSAGES = {
  PHOTO: 'Photo is required!',
  DOCUMENT: 'Document upload is required!',
  MARKETING_PREFERENCES: 'Marketing Preference is required!',
};

export const PAWNBROKING_ACTIONS = [
  { id: 1, value: 'Withdraw Later' },
  { id: 2, value: 'Early Settlement' },
  { id: 3, value: 'Print Bagslip' },
];

export const WITHDRAW_LATER = {
  TITLE: 'Does the customer want to give notice to withdraw this pledge ',
  RIGHT_TO_WITHDRAW:
    'The customer has chosen to exercise their right to withdraw within the first 14 days of this agreement.',
  METHOD_OF_NOTIFICATION: 'Method of Notification',
  MAX_WITHDRAW_VALUE: 'Maximum Withdraw Value',
  NOTICE_GIVEN: 'Notice Given',
  EXPIRY_DATE: 'Expiry Date',
};

export const EARLY_SETTLEMENT = {
  TITLE: 'Early Settlement Request',
  MAX_SETTLEMENT_VALUE: 'Maximum Settlement Value',
  NOTICE_GIVEN: 'Notice Given',
  EXPIRY_DATE: 'Expiry Date',
};

export const CUSTOMER_EDIT = {
  CUSTOMER_PROFILE_UPDATE: 'The following details has been succesfully updated',
};

export const FIELD_LABELS = {
  customerImage: 'Customer Image',
  title: 'Title',
  firstNames: 'First Name',
  middleName: 'Middle Name',
  surname: 'Last Name',
  preferredName: 'Preferred Name',
  preferredLanguageId: 'Preferred Langauge',
  dateOfBirth: 'Date of Birth',
  postcode: 'Postcode',
  countryId: 'Country',
  property: 'Flat/House Number',
  houseName: 'House Name',
  street: 'Street/Road',
  town: 'Town',
  area: 'Area',
  county: 'County',
  emailAddress: 'Email',
  mobile: 'Mobile No',
  pin: 'Pin',
  telephone: 'Home Telephone No',
  prefersEmailContact: 'Communication Preference',
  marketingSmsAccepted: 'Marketing Preference',
  marketingEmailAccepted: 'Marketing Preference',
  marketingPhoneAccepted: 'Marketing Preference',
  marketingPostAccepted: 'Marketing Preference',
  marketingOptOut: 'Marketing Preference',
  referenceNumber: 'Blue Light Member - Reference Number',
  expiryDate: 'Blue Light Member - Expiry Date',
  hasBlueLightMembership: 'Blue Light Member',
  docType: 'ID Type',
  docDateofExpiry: 'Date of Expiry',
};

export const OCR_MESSAGE = 'Please wait for the information to populate.';

export const DEFAULT_SCAN_MESSAGE = 'Please wait.';
export const SCAN_MESSGAE = 'Document being Scanned.';
export const UPLOAD_DOC_MESSAGE = 'Uploading Document';
export const PRINTING_DOCUMENT = 'Document being Printed.';

export const NOTES_FILTERS = {
  ALL: 'All',
  STORE: 'Store',
  AT_RISK: 'At Risk',
  SSTEAM: 'SSTeam',
};

export const NOTES_FILTERS_VALUE = {
  ALL: 0,
  STORE: 1,
  SSTEAM: 2,
  AT_RISK: 3, //CST
};

export const CONSENT_TYPE = {
  REFUSED: 'Refused',
  GRANTED: 'Granted',
};

export const CUSTOMER_SUMMARY_PANES = [
  { title: 'Overview', key: '1', width: '14%' },
  { title: 'Pawnbroking', key: '2', width: '14%' },
  { title: 'Purchase', key: '3', width: '14%' },
  { title: 'Retail', key: '4', width: '14%' },
  { title: 'Layaways', key: '5', width: '14%' },
  { title: 'Travel Money', key: '6', width: '15%' },
  { title: 'Cheque Cashing', key: '7', width: '15%' },
  // { title: 'Repairs', key: '9', width: '13%' },
];

export const LAST_VISITS_TITLE = {
  LAST_SEEN: 'Last seen at...',
  DATE_AND_TIME: 'Date & Time',
  NO_RECORD_FOUND: 'No record found....',
  STORE: 'Store',
};

export const VISIT_SERVICE_TYPE = {
  PLEDGE: 'Pledge',
  PLEDGE_PAYDOWN: 'Pledge Paydown',
  PLEDGE_RENEWAL: 'Pledge Renewal',
  PLEDGE_PICKUP: 'Pledge Pickup',
  PLEDGE_WITHDRAWAL: 'Pledge Withdrawal',
  PLEDGE_PARTIAL_PAYMENT: 'Pledge Partial Payment',
  RETAIL_SALE: 'Retail sale',
  RETAIL_REFUND: 'Retail Refund',
  CHEQUE_CASHING: 'Cheque Cashing',
  TRAVEL_MONEY: 'Travel Money',
  WESTERN_UNION: 'Western Union',
  PURCHASE: 'Purchase',
  PURCHASE_RETURN: 'Purchase Return',
  LAYAWAY: 'Layaway',
  AFFIDAVIT: 'Affidavit',
  PLEDGE_SURPLUS_PAYMENT: 'Pledge Surplus Payment',
  CUSTOMER_DETAILS_UPDATE: 'Customer details update',
};

export const LAST_SERVICE = {
  PLEDGE_AGREEMENT_REF: 'Pledge Agreement Ref',
  NUMBER_OF_ITEMS: 'Number of items',
  WEIGHT: 'Weight',
  AMOUNT: 'Amount',
  PREVIOUS_PLEDGE_REF: 'Previous Pledge Ref',
  NEW_PLEDGE_REF: 'New Pledge Ref',
  LOAN_AMOUNT: 'Loan Amount',
  NEW_LOAN_AMOUNT: 'New Loan Amount',
  PLEDGE_REF: 'Pledge Ref',
  AMOUNT_PAID: 'Amount Paid',
  RETAIL_REF: 'Retail Ref',
  ISSUER_NAME: 'Issuer Name',
  CHEQUE_NUMBER: 'Cheque Number',
  RETAINED_AMOUNT: 'Retained Amount',
  PAID_AMOUNT: 'Paid Amount',
  CURRENCY: 'Currency',
  FROM: 'From',
  TO: 'To',
  MTCN_REF: 'MTCN',
  DIRECTION: 'Direction',
  RETURN: 'Return',
  PURCHASE_REF: 'Purchase Ref',
  LOAN_VALUE: 'Loan Value',
  SOLD: 'Sold',
  DEPOSIT_WITHDRAWAL: 'Deposit/Withdrawal',
  DEPOSIT: 'Deposit',
  WITHDRAWAL: 'Withdrawal',
  SURPLUS_AMOUNT: 'Surplus Amount',
  STORE: 'Store',
  PAYDOWN: 'PAYDOWN',
  PICKUP: 'PICKUP',
  CASH: 'CASH',
  RENEWAL: 'RENEWAL',
  WITHDRAW: 'WITHDRAW',
  PURCHASE_VALUE: 'Purchase Value ',
  ORIGINAL_VALUE: 'Original Value ',
  AFFIDAVIT_FEE: 'Affidavit Fee',
  AMOUNT_RECEIVED: 'Amount Received',
  ACTION: 'Action',
  FULL_PAYOUT: 'Full Payout',
  PART_PAYOUT: 'Part Payout',
};

export const THIRD_PARTY_ASSISTANT = {
  OR_TEXT: 'OR',
  TPA_HEADING: 'Third Party Assistant',
};

export const RELATIONSHIP_TO_CUSTOMER = [
  { title: 'Relative', key: '1' },
  { title: 'Friend', key: '2' },
  { title: 'Professional', key: '3' },
  { title: 'Guardian', key: '4' },
  { title: 'Carer', key: '5' },
  { title: 'Solicitor', key: '6' },
  { title: 'Partner', key: '7' },
  { title: 'Husband/ Wife', key: '8' },
  { title: 'Other', key: '9' },
];

export const CUSTOMER_PROFILE_STATUS = [
  'green',
  'yellow',
  'red',
  'purple',
  'black',
  'amber',
];

export const THIRD_PARTY_DETAILS_ADDED = 'Third Party Assistance details added';

export const BUTTONS_LABEL = {
  CLICK_COLLECT: 'Click & Collect',
  CASH_TRANSFER: 'Cash Transfer',
  CASH_DELIVERY: 'Cash Delivery',
  PETTY_CASH: 'Expense In/Out',
};

export const ERROR_MESSAGE_ON_PEPS_SANCTION = {
  MESSAGE: 'Alert: Account is Blocked',

  DESCRIPTION: `The customer's account is temporarily frozen while a review is undertaken by the Financial Crime Team (FCT). `,
  SECOND_DESC:
    'Please await further guidance from FCT who will confirm when the account can be released.',
};

export const BLUELIGHT_MEMBER_DISCOUNT = {
  MESSAGE: 'This customer is eligible for Blue Light Membership Discount',
};

export const ERROR_MESSAGE_ON_NORMAL_BLOCK = {
  MESSAGE: 'Alert',
  DESCRIPTION:
    'The customer account is currently Blocked, please contact the FC Team if you wish to continue.',
};

export const ERROR_MESSAGE_ON_NOT_VALID_STORE = {
  DESCRIPTION:
    'You have selected a different store to where you are currently working. Please EXIT if this is in error or select continue to proceed.’',
};

export const CUSTOMER_MESSAGE = {
  CREATE_SUCCESS: 'Customer created successfully',
  UPLOADING_MESSAGE: 'Uploading customer Image and Id(s)',
  UPLOAD_SUCCESS: 'Documents uploaded successfully!',
  UPLOAD_ERROR: 'Unable to uploaded customer documents.',
};

export const ID_ERROR_MESSAGE = {
  DOCUMENT_UPLOAD: 'Document upload is required',
  EXPIRY_DATE: 'Date of Expiry is Required',
};

export const SERVICES = {
  PLEDGE: 'pledgesList',
  RETAIL: 'retailHistory',
  CHEQUE: 'chequeHistory',
  FX: 'fxHistory',
  WU: 'westernUnionHistory',
  PURCHASE: 'purchaseHistory',
  LAYWAY: 'laywayHistory',
  CUSTOMER_VISIT: 'customerLastStoreVisit',
};

export const CUSTOMER_DETAILS_UPDATED = {
  Photo: 'Photo',
  IdShownDate: 'ID',
  Title: 'Title',
  FirstNames: 'First Name',
  MiddleName: 'Middle Name',
  Surname: 'Last Name',
  PreferredName: 'Preferred Name',
  DateOfBirth: 'Date of Birth',
  Property: 'Property',
  HouseName: 'House Name',
  Street: 'Street/Road',
  Area: 'Area',
  Town: 'Town',
  County: 'County',
  CountryId: 'Country',
  PostCode: 'Post Code',
  EmailAddress: 'Email',
  Mobile: 'Mobile Number',
  Telephone: 'Home Telephone No.',
  PrefersEmailContact: 'Communication Preference',
  MarketingEmailAccepted: 'Marketing Preference',
  MarketingPostAccepted: 'Marketing Preference',
  MarketingSmsAccepted: 'Marketing Preference',
  MarketingPhoneAccepted: 'Marketing Preference',
};

export const VALUATION_MOBILE_EMAIL_ERROR =
  'Email ID or Mobile Number is required';


export const EMAIL_ADDRESS = {
  NO_EMAIL_ADDRESS: 'No Email Address',
  EMAIL_ERROR_MESSAGE: 'Email is not a valid email!',
};

export const ENTER_KEY_CODE = 13;

