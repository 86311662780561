export const ROUTE_CONFIG = {
  CUSTOMER: 'customer',
  SUMMARY: 'summary',
  NEW: 'new',
  EDIT: 'edit',
  MODE: 'mode',
  PLEDGE: 'pledge',
  PICKUPRENEW: 'pledgeDetails',
  RETAILSALES: 'retail',
  BASKET: 'basket',
  TRAVEL_MONEY: 'travelMoney',
  WESTERN_UNION: 'western-union',
  CHEQUE_CASHING: 'chequeCashing',
  PAYMENT: 'payment',
  CHECKOUT: 'checkout',
  CASH_MGMT: 'cashManagement',
  PLEDGE_MANAGEMENT: 'pledge-management',
  INVENTORY_MANAGEMENT: 'inventory-management',
  ADMIN: 'admin',
  VALUATION_CENTER: 'valuation-center',
  CUSTOMERSEARCH: 'customer-search',
  CONSUMER_DUTY: 'consumer-duty',
};

export const DISABLED_CUSTOMER_HEAD = [
  ROUTE_CONFIG.CHECKOUT,
  ROUTE_CONFIG.PAYMENT,
  ROUTE_CONFIG.CUSTOMER,
];

export const NOTIFICATION_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
  GENERIC_API_ERROR: 'Something went wrong, Please try again',
};

export const URL_TYPE = {
  PLEDGE: 'pledge',
  NO_URL: 'noUrl',
  RETAIL: 'retail',
  CHEQUE_CASHING: 'chequeCashing',
};

export const GRID_NO_URL = 'noUrl';
export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_FORMAT_MONTH = 'MM/DD/YYYY';
export const DATE_FORMAT_YEAR = 'YYYY-MM-DD';
export const DATE_FORMAT_TWO_DIGIT_YEAR = 'DD/MM/YY';
export const FNS_DATE_FORMAT = 'dd/MM/yyyy';
export const FNS_DATE_FORMATNEW = 'YYYY/MM/DD';
export const FNS_DATE_FORMAT_TWO_DIGIT = 'dd/MM/yy';
export const FNS_DATE_FORMAT_TWO_DIGIT_WITH_TIME = 'dd/MM/yy | HH:mm';
export const FNS_DATE_FORMAT_YEAR = 'yyyy-MM-dd';

export const FNS_DATE_FORMAT_WITH_TIME = 'dd/MM/yyyy, hh:mm aaaa';
export const FNS_DATE_FORMAT_WITH_TIME_WEEKDAY_NAME =
  'ccc, dd/MM/yy, hh:mm aaaa';
export const FNS_DATE_FORMAT_WITH_CAP_TIME = 'dd/MM/yyyy, HH:mm a';

export const OPTIONS_RADIO_NOT_DISABLE = ['Local (Layaway)', 'Local'];
export const NO_ITEMS = 'No Items';

export const DAYS_OF_WEEK = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const FOOTER_BUTTONS_AND_TEXT = {
  PAY_TO_CUSTOMER: 'Pay to Customer',
  TAKE_FROM_CUSTOMER: 'Take from Customer',
  PROCEED_TO_BASKET: 'Proceed to Basket',
  PROCEED_TO_CHECKOUT: 'Proceed to Checkout',
  PROCEED_TO_Payment: 'Proceed to Payment',
  PROCEED_TO_NEXT_STEP: 'Next Step',
  EXIT: 'Exit',
  BACK_TO_BASKET: 'Back to Basket',
  ALL_SERVICE_LABEL: 'Total of all services',
  PRINT_RECIEPT: 'Print Receipt(s)',
  EXIT_AND_SAVE: 'Exit & Save',
  PROCEED_TO_VOID: 'Void Transaction',
  COMPLETE_AND_END: 'Complete and End Customer Transaction',
};

export const COLORS_CODE = {
  HOME: 'light-blue',
  PLEDGE: 'mustard',
  PURCHASE: 'mustard',
  PICKUPRENEW: 'dodger-blue',
  RETAIL_SHOPING: 'orchid',
  LAYWAYS: 'crayola-blue',
  TRAVELMONY: 'sea-green',
  WESTERN_UNION: 'mulberry',
  CHEQUE_CASHING: 'brick-red',
  PERSONAL_LOAN: 'slate-blue',
  REPAIR_SERVICES: 'camel',
  CASH_MGMT: 'sky-blue',
  IVENTORY_MGMT: 'light-sea-green',
  PLEDGE_MANAGEMENT: 'burnt-sienna',
  ADMIN: 'slate-blue',
  SURPLUS: 'dodger-blue',
  CONSUMER_DUTY: 'orange', //CST/SST
};

export const COLORS_CODE_TABLE = {
  PLEDGE: 'mustard-rgba',
  PURCHASE: 'mustard-rgba',
  PURCHASE_RETURN: 'mustard-rgba',
  PICKUPRENEW: 'dodger-blue-rgba',
  RETAIL_SHOPPING: 'orchid-rgba',
  LAYWAYS: 'crayola-blue-rgba',
  TRAVELMONEY: 'sea-green-rgba',
  WESTERN_UNION: 'mulberry-rgba',
  CHEQUE_CASHING: 'brick-red-rgba',
  PERSONAL_LOAN: 'slate-blue-rgba',
  REPAIR_SERVICES: 'camel-rgba',
  CASH_MGMT: 'sky-blue-rgba',
  IVENTORY_MGMT: 'light-sea-green-rgba',
};

export const SERVICE_TYPE = {
  //add service types as and when required
  CHEQUE_CASHING: 'CHEQUE_CASHING',
  PICKUP: 'PICKUP',
  RETAIL: 'RETAIL',
  LITE_CUSTOMER: 'LITE CUSTOMER',
  LOAN_EXCEPTION: 'LOAN EXCEPTION',
};

export const BASKET_SERVICE_TYPE = {
  PLEDGE: 1,
  PURCHASE: 2,
  RETAIL: 4,
  FX: 5,
  PICKUPRENEW: 8,
  CHEQUECASHING: 6,
  WU: 9,
  PURCHASERETURN: 11,
  SURPLUS: 16,
};

export const SERVICE_TYPE_LABEL_BY_KEY = {
  PLEDGE: 'Pledge',
  PURCHASE: 'Purchase',
  RETAIL: 'Retail',
  FX: 'Travel Money',
  PICKUPRENEW: 'Pickup/Renew',
  CHEQUECASHING: 'Cheque Cashing',
  WU: 'Western Union',
  PURCHASERETURN: 'Purchase Return',
  SURPLUS: 'Surplus',
};

export const SERVICE_TYPE_AS_LIST = [
  { id: BASKET_SERVICE_TYPE.PLEDGE, title: SERVICE_TYPE_LABEL_BY_KEY.PLEDGE },
  {
    id: BASKET_SERVICE_TYPE.PURCHASE,
    title: SERVICE_TYPE_LABEL_BY_KEY.PURCHASE,
  },
  { id: BASKET_SERVICE_TYPE.RETAIL, title: SERVICE_TYPE_LABEL_BY_KEY.RETAIL },
  { id: BASKET_SERVICE_TYPE.FX, title: SERVICE_TYPE_LABEL_BY_KEY.FX },
  {
    id: BASKET_SERVICE_TYPE.PICKUPRENEW,
    title: SERVICE_TYPE_LABEL_BY_KEY.PICKUPRENEW,
  },
  {
    id: BASKET_SERVICE_TYPE.CHEQUECASHING,
    title: SERVICE_TYPE_LABEL_BY_KEY.CHEQUECASHING,
  },
  {
    id: BASKET_SERVICE_TYPE.WU,
    title: SERVICE_TYPE_LABEL_BY_KEY.WU,
  },
  {
    id: BASKET_SERVICE_TYPE.PURCHASERETURN,
    title: SERVICE_TYPE_LABEL_BY_KEY.PURCHASERETURN,
  },
  {
    id: BASKET_SERVICE_TYPE.SURPLUS,
    title: SERVICE_TYPE_LABEL_BY_KEY.SURPLUS,
  },
];

export const SERVICE_TYPE_TITLE_BY_ID = [
  { id: 1, title: 'Pledge' },
  { id: 2, title: 'Purchase' },
  { id: 8, title: 'Pickup/Renew' },
  { id: 4, title: 'Retail' },
  { id: 5, title: 'Travel Money' },
  { id: 6, title: 'Cheque Cashing' },
  { id: 9, title: 'Western Union' },
  { id: 16, title: 'Surplus' },
];

export const BASKET_STATUS = {
  PENDING: 1,
  INITIALISED: 2,
  SUCCESSFUL: 3,
  FAILED: 4,
  CANCELLED: 5,
  EXPIRED: 6,
  PAYMENTSUCCESSFUL: 7,
};

export const PLEDGE_MANAGEMENT_TABS = {
  LEDGER: 'Ledger',
  AUCTION_OVER: 'Auction over 75.00',
  PFI: 'PFI 75.00 and under',
  AUCTION_MANAGER: 'Auction Manager',
  CLOSED_AUCTION: 'Closed Auctions',
  CONFISCATIONS: 'Confiscations',
  VULNERABLE: 'Vulnerable',
  INITIALPANES: [
    { title: 'Ledger', width: 250 },
    { title: 'Auction over 75.00', width: 250 },
    { title: 'PFI 75.00 and under', width: 250 },
    { title: 'Auction Manager', width: 250 },
    { title: 'Closed Auctions', width: 250 },
    { title: 'Confiscations', width: 250 },
    { title: 'Vulnerable', width: 250 },
  ],
  INITIAL_PANES_NOT_AUCTIONS: [
    { title: 'Ledger', width: 250 },
    { title: 'Confiscations', width: 250 },
    { title: 'Vulnerable', width: 250 },
  ],
};

export const ADMIN_TABS = {
  VOID: 'Void (Completed Transactions)',
  REPORTS: 'Reports',
  USER_MANAGER: 'User Manager',
  TRAVEL_MONEY_RATES: 'Travel Money Rates',

  INITIALPANES: [
    { title: 'Void (Completed Transactions)', width: 280 },
    { title: 'User Manager', width: 280 },
    { title: 'Travel Money Rates', width: 280 },
    { title: 'Reports', width: 280 },
  ],
};

export const VAT_CUSTOMER_LABEL = {
  HEADING:
    'Second hand goods VAT scheme requires a customers full name and address',
  CUSTOMER_ABSTAINED: 'Customer abstained from providing personal details',
};

export const ADMIN_MANAGEMENT_TABS = {
  VOID_TRANSACTIONS: 'Void Transactions',
  REPORTS: 'Reports',
};

export const SIGNALR_TIMEOUT = 30;

export const REGEX_AMOUNT = new RegExp(/^-?\d*\.?\d*$/); // regex to accept numbers with multiple decimal places
export const REGEX_NUMBER_ONLY = /^([-]?[1-9][0-9]*|0)$/; // pattern to accept number only
export const REGEX_POSTCODE = new RegExp(
  /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/
);
export const PATTERN_INTEGER_ONLY = /^[0-9-]*$/;

//Exceed amount for check customer Finder when proceed to checkout
export const EXCEED_AMOUNTS = {
  RETAIL: 500,
  RETAIL_LIST_VALUE: 8500,
  FX: 850,
};

export const RETAIL_VALUE_TYPE = {
  COST_VALUE: 'costValue',
  LIST_VALUE: 'listValue',
};

export const AUCTION_STATUS = {
  LIVE_STORE: 1,
  PENDING_RETURN: 4,
  CLOSED: 6,
  LIVE_AUCTION: 2,
  READY_FOR_AUCTION: 5,
};

export const RESPONSE_STATUS = {
  SUCCESS: 200,
  NO_CONTENT: 204,
  SERVICE_UNAVAILABLE: 503,
  BAD_REQUEST: 400,
  NOT_FOUND: 404,
};

export const ALERT = {
  DURATION: {
    SHORT: 3,
    GENERIC: 7,
    LONG: 10,
  },
  STATUS: {
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
  },
};

export const VOID_STATUS = {
  VOID_SUCCESS: 3,
  PARTIAL_VOID_SUCCESS: 2,
};

export const GUEST_TYPES = {
  VALUATION: 'Guest',
  FX: 'Travel Money',
  VAT: 'VAT Customer',
};

export const TM_AMOUNT_ALERT =
  'Alert: The customer’s Travel Money transactions have exceeded £850.00 in value, ID is now required';

export const GENERIC_ERROR = 'Something went wrong. Please try again later';

export const PAYMENT_MODES = {
  CASH: 'Cash',
  CARD: 'Card',
  LAYAWAY_FUNDS: 'Layaway Funds',
  BANKTRANSFER: 'Bank Transfer',
  VOUCHER: 'Voucher',
  CREDIT_SCORE: 'Credit Score',
  ONLINE_PAYMENT: 'Online Payment',
  VIS_DEPOSIT_ONLINE_PAYMENT: 'VIS Online Deposit Payment',
};

export const ITEM_STATUS = {
  LAYAWAY: 'Layaway',
  TO_RETURN: 'To Return',
};

export const VERSION_ALERT_MESSAGE = {
  ALERT_LINE_ONE:
    'A new version of Evo is available. Your browser will automatically try to load this version.',
  ALERT_LINE_TWO:
    'In case you see the same message again, close all browsers and try again, or contact IT Support.',
};

export const LOCAL_HOST = 'localhost';
export const REGEX_MOBILE = '^[+]?[0-9 ]{11}$';

export const PROFANITY_CHECK = {
  REMOVE_PROFANITY:
    'You cannot proceed because a suspected profanity has been used, please remove the following word(s) in order to continue.',
  PROFANITY_WORDS: 'Suspected profanity words:',
};

export const ADD_PROFANITY_WORDS = [
  'shitfaced',
  'shitface',
  'bullshit',
  'smackhead',
  'crackhead',
  'dickweed',
  'bitchy',
  'goddammit',
  'goddamit',
  'idiot',
  'idiotic',
  'stupid',
  'gay',
  'lesbian',
  'fecked',
  'wench',
  'prostitute',
  'prozzi',
  'prozie',
  'arsehole',
  'fuckoff',
  'fucksake',
  'spaz',
  'tosspot',
  'bollocks',
  'poo',
  'shitbag',
  'bellend',
  'pisshead',
  'smeghead',
  'smeg',
  'gimp',
  'shitmagnet',
  'fuckface',
  'sheepshagger',
  'faggert',
  'flid',
  'flyd',
  'queer',
];

export const BYPASS_PROFANITY_WORDS = [
  'pawn',
  'pawnbroking',
  'pawning',
  'pawned',
  'dyke',
  'dike',
];

export const REFRESH_TIME_RESERVED_CURRENCY = 30 * 60 * 1000; // every 30 mins

export const ALLOWED_KEY_HITS = [
  'ArrowRight',
  'ArrowLeft',
  'Backspace',
  'Delete',
];

export const CUSTOMER_POPUP_WITHOUT_LAST_SEEN = [
  '/',
  `/${ROUTE_CONFIG.CUSTOMERSEARCH}`,
  `/${ROUTE_CONFIG.CONSUMER_DUTY}`,
];

export const AUCTION_BAG_STATUS = {
  OPEN: 1,
  SEALED: 2,
};

export const AUCTION_BAG_CATEGORY = {
  STATUS: 1,
  LOCATION: 2,
};

export const DEFAULT_PAGINATION_CONFIG = {
  pageSize: 20,
};

export const PRINTER_MESSAGE = {
  PRINT_SUCCESS: 'Sent to printer successfully',
  PRINT_ERROR_DESCRIPTION: 'Barcode service is not running, please check.',
  BAGSLIP_ERROR: 'Unable to print bagslip.',
  PRINTER_SERVICE_ERROR: 'Printer service is not running, please check.',
};

export const COMMON_BUTTON_LABELS = {
  SUBMIT: 'Submit',
  RESET: 'Reset',
  PRINT: 'Print',
  NO: 'No',
  YES: 'Yes',
  ADD: 'Add',
  CANCEL: 'Cancel',
};

export const CURRENCY_CODE = {
  GBP: 'GBP',
};

export const PRINT_TYPE = {
  RECEIPT: 'printreceipt',
  CONTRACT: 'printcontract',
  BARCODE: 'printbarcode',
  PASS: 'printpass',
};

export const USER_DETAILS_ERROR = 'User/Store details not found.';
export const UNABLE_UPLOAD_ERROR = 'Unable to upload image.';
export const LOGIN_ERROR = 'Something went wrong. Please login again.';

export const DATE_SELECTION_ERROR = {
  INVALID_DATE_RANGE: 'From Date cannot be greater than To Date',
};

export const SORTING = {
  ASCENDING: 'asc',
  DESCENDING: 'desc',
};

export const HELP_ICON_POSITION = {
  BEFORE: 'BEFORE',
  AFTER: 'AFTER',
};

//breakpoints based on antd grid layout
export const MEDIA_SIZE = {
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1600px',
};

export const ITEM_SERVICE_TYPE = {
  PLEDGE: 'pledge',
  PURCHASE: 'purchase',
};

export const STORE_ID = {
  ONLINE: '6100',
};
